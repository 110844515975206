@keyframes loading {
  0% {
    transform: translateX(0);
  }
  50%,
  100% {
    transform: translateX(460px);
  }
}
/* CART PAGE CSS */
#cart_box h3 {
  position: relative;
  overflow: hidden;
}

.skeleton-h {
  background: #f2f2f2 !important;
}

.skeleton-h::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 30px;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
}

.skeleton-cart_table tbody tr td {
  position: relative;
  overflow: hidden;
}

.skeleton-tr {
  background: #f2f2f2 !important;
  height: 38px;
  border-bottom: 2px solid #fff;
}

.skeleton-tr::before {
  content: "";
  position: absolute;
  left: 0;
  width: 60px;
  height: 38px;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
  border-bottom: 2px solid #fff;
}

.skeleton-cart-row {
  padding-top: 20px;
  background: #f2f2f2 !important;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  overflow: hidden;
}

.skeleton-cart-row::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 20px;
  padding-right: 15px;
  padding-left: 15px;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
}

.skeleton-btn {
  background: #f2f2f2;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 20px;
  display: block;
  border-radius: 5px;
  margin-top: 10px;
}

.skeleton-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
  width: 30px;
  height: 40px;
}
/*  HOME PAGE CSS */
.skeleton-slider {
  width: 100%;
  height: 400px;
  background: #f2f2f2 !important;
}

.skeleton-slider::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
}

.skeleton-cat-img-block {
  position: relative;
  overflow: hidden;
  border-radius: 50% !important;
  margin: 10px 10px;
  width: 90% !important;
  height: 100px;
  float: left;
  background: #f2f2f2 !important;
}

.skeleton-cat-img-block::before {
  content: "";
  position: absolute;
  border-radius: 50% !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
}

.skeleton-map-right {
  height: 800px;
  width: 100%;
  padding: 0px;
  background: #f2f2f2 !important;
}

.skeleton-map-right::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 800px;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
}

.skeleton-thumb_strip {
  background: #f2f2f2 !important;
  left: 0;
  top: 0;
  width: 110px;
  height: 110px;
  overflow: hidden;
  padding: 5px;
  text-align: center;
  box-sizing: border-box;
  position: absolute;
}

.skeleton-thumb_strip::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 110px;
  width: 110px;
  overflow: hidden;
  padding: 5px;
  text-align: center;
  box-sizing: border-box;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
}

.skeleton-shop_name {
  width: 50%;
  height: 20px;
  margin-bottom: 10px !important;
  background: #f2f2f2 !important;
}

.skeleton-shop_desc1 {
  width: 70%;
  height: 15px;
  margin-bottom: 10px !important;
  background: #f2f2f2 !important;
}

.skeleton-shop_desc2 {
  width: 90%;
  height: 15px;
  background: #f2f2f2 !important;
}

.skeleton-btn_1 {
  background: #f2f2f2 !important;
  width: 50%;
  height: 20px;
  padding: 10px 65px;
}

/* ORDER LIST */
.skeleton-parallax-window {
  width: 100%;
  height: 350px;
  background: #f2f2f2 !important;
}

.skeleton-parallax-window::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 350px;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
}

/* ORDER DETAIL */

.skeleton-bs-wizard {
  width: 100%;
  height: 100px;
  background: #f2f2f2 !important;
  margin: 0px 0px 15px 0px;
}
.skeleton-bs-wizard::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 100px;
  margin: 0px 0px 15px 0px;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
}

.skeleton-col-lg-6-short {
  width: 100%;
  height: 200px;
  background: #f2f2f2 !important;
  overflow: hidden;
}
.skeleton-col-lg-6-short::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
}

.skeleton-col-lg-6 {
  width: 100%;
  height: 800px;
  background: #f2f2f2 !important;
  overflow: hidden;
}
.skeleton-col-lg-6::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 800px;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
}

.skeleton-col-lg-3 {
  width: 100%;
  height: 800px;
  background: #f2f2f2 !important;
  overflow: hidden;
}
.skeleton-col-lg-3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 800px;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
}
